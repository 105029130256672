import React,{useState,useEffect,useRef } from 'react';
import {
   Modal, ModalHeader, ModalBody,
  Card, CardBody, ButtonToolbar, ButtonGroup, Row, Col, Badge
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';
import Moment from 'moment';
import moment from 'moment'
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes, { element } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PaginatorComponent from '../Cruds/CommonDataTable/PaginatorComponent';

//components 
import AutoComplete from './components/AutoComplete';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import Select from '../../shared/components/form/Select';
import RadioButton from '../../shared/components/form/RadioButton';
import DefaultInput from '../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import DatePicker from '../../shared/components/form/DatePicker';
import TimePicker from '../../shared/components/form/TimePicker';
import renderRadioButtonField from '../../shared/components/form/RadioButton';
import UserPasswordResetModal from '../Cruds/CommonModals/UserPasswordResetModal';
import { Dropdown } from 'primereact/dropdown';
import RenderFileInputField from '../Form/components/FileUpload';
// import {CKEditor} from 'ckeditor4-react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import TextEditorTwo from '../../shared/components/TextEditor';

import config from '../../config/config';
import configMessages from '../../config/configMessages';
import apiCalls from '../../config/apiCalls';
import fetchMethodRequest from '../../config/service';
import DataTables from '../Cruds/CommonDataTable/DataTable';
// Toaster message
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import EyeIcon from 'mdi-react/EyeIcon';
import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';

// Multi select Dropdown
import MultiSelectDropDown from './components/MultiSelect';

// Loader
import Loader from '../App/Loader';
// Calendar
//session expiry modal
import DeleteRowModal from '../Cruds/CommonModals/DeleteRowModal';
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal'
//import TicketCommentsInfo from '../Tables/PrimeReactTable/Tickets/components/TicketCommentsInfo';
import { th } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'smooth-scrollbar-react';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import DoInputField from "./Fields/DoInputField";
import DoPasswordField from "./Fields/DoPasswordField";
import DoDateField from './Fields/DoDateField';
import DoSelectField from './Fields/DoSelectField';
import DoTextareaField from './Fields/DoTextareaField';
import DoMultiSelectField from './Fields/DoMultiSelectField';
import DoRadioButtons from './Fields/DoRadioButtons';
import DoCheckboxField from './Fields/DoCheckboxField';
import DoAutoCompleteField from './Fields/DoAutoCompleteField';
import DoEditor from './Fields/DoEditor';
import { getPasswordRegex } from './DoValidations';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DoFileUpload from './Fields/DoFileUpload';
import DisableButton from './DisableButton';
import Chat from '../Cruds/CommonModals/CommentsModal';
import FileGalleryView from '../Cruds/CommonDataTable/FileGalleryView';
import AddNotesModal from '../Cruds/CommonModals/AddNotesModal';
import DoCKEditorField from './Fields/DoEditor';
import { dateAndTimeFormat, updateCkEditorValues } from '../../config/configData';
import { formatIndianCurrency } from '../../utils/utils';


let radioRequired = value => {
  let error = undefined;
  if (value || typeof value === 'string') {
    error = undefined
  } else {
    error = configMessages.fillRadio
  }
  return error
}
let required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);
let normalizePhone = (value) => {
  if (!value) {
    return value
  }
  let onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}


let FormModal = (props) => {
  
  let [displayBreadCrumbValue,setDisplayBreadCrumbValue] = useState(props.displayBreadCrumbValue);
  let [displayBreadCrumbField,setDisplayBreadCrumbField] = useState(props.displayBreadCrumbField);
  let [userStatus,setUserStatus] = useState(props.userStatus);
  let [formFields,setFormFields] = useState(props.formFields());
  let [formValues,setFormValues] = useState({});
  let [colored,setColored] = useState(false);
  let [header,setHeader] = useState(true);
  let [isLoading,setIsLoading] = useState(false); 
  let [defaultValue,setDefaultValue] = useState(true);
  let [roleOptions,setRoleOptions] = useState([]);
  let [slno,setSslno] = useState(0);
  let [rowData,setRowData] = useState([]);
  let [originalTableFields,setOriginalTableFields] = useState(props.originalTableFields);  
  let [actions,setActions] = useState('');
  let [confirmType,setConfirmType] = useState('');
  let [confirmModalText,setConfirmModalText] = useState('');
  let [activitiesData,setActivitiesData] = useState([]);
  let [totalRecordsLength,setTotalRecordsLength] = useState(0);
  let [first,setFirst] = useState(0);
  let [rows,setRows] = useState(10);
  let [page,setPage] = useState(1)
  let [showorHideLevelsField,setShoworHideLevelsField] = useState(false);
  let [filterCriteria, setFilterCriteria] = useState({ limit: '', page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
  let [editRowDataID,setEditRowDataID] = useState();
  let [formType,setFormType] = useState(props.formType);
  let [userData,setUserData] = useState();
  let [menuList,setMenuList] = useState(props.menuList ? props.menuList : []);
  let [selectActions,setSelectActions] = useState();
  let [selectedRows,setSelectedRows] = useState();
  let [sortCount,setSortCount] = useState();

  let [sessionExpiryModal,setSessionExpiryModal] = useState(false);
  let [openUserPasswordResetModal,setOpenUserPasswordResetModal] = useState(false);
  let [openDeleteModal,setOpenDeleteModal] = useState(false);
  let [dropValueForEdit,setDropValueForEdit]=useState('');
  // let [isEditFields, setIsEditFields] = useState(false);
  let [selectedData, setSelectedData] = useState('');
  let [selectedId, setSelectedId] = useState(props.selectedId);
  let [isUpdateDisable, setIsUpdateDisable] = useState(true);
  const [fieldValidation, setFieldValidation] = useState(false);
  let [display, setDisplay] = useState(false);
  let [viewData, setViewData] = useState('');
  const [selectedArea, setSelectedArea] = useState({});

  let [schema,setSchema] = useState(''); 


  if (props.onRef) {
    props.onRef({
      handleViewDisplay,
      getRowData,
      getFormFields,
      addNotes
    });
  }

  useEffect(() => {

    /**@Changing indexValue of the modal */

    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
      modal.style.zIndex = '300';
    }

    let tschema = setValidations(props.formFields());
    setSchema(tschema)
    let sessionexpired = localStorage.getItem('sessionexpired');
    if (sessionexpired) {
      setSessionExpiryModal(true);
    }   
    getActivities(selectedId);
    getFormFields(formType);
    let recordId = selectedId ? selectedId : props.tableRowData._id;
    let selectedData = formType !== 'add' ? getSelectedData(recordId,props.allUsersData) : {} ;
    setSelectedData(selectedData)
    if (formType === 'view') {      
      setSslno(selectedData.Sno);
      handleViewDisplay(selectedData, formType);
      setViewData(selectedData);
     }
    else if (formType === 'edit') {
      setSslno(selectedData.Sno);   
      getRowData(selectedData,'edit');
    }

    //This is for correct working of forms when fields are provided with default values
    if (formType === 'add') {
      let fields = props.formFields();
      fields.forEach((item) => {
        if (item.value && item.name) {
          setValue(item.name, item.value)
          if (item.name === 'office') {
            handleFnEnableControlsBasedOnValue2(item.value, item.dependent, item.name);
          }
        } else {
          if ((item?.type === 'dropDown' && item?.isMultiSelect) || (item?.type === 'relateAutoComplete' && item?.isMultiple)) {
            setValue(item.name, [])
          } else if (['email', 'dropDown', 'text', 'relateAutoComplete'].includes(item?.type)) {
            setValue(item.name, '')
          } else {
            setValue(item.name, null)
          }
        }
      })
    }
  }, []);

  
  useEffect(() => {
    if (props.type === "Roles") {
      const setInitialValues = () => {
        setValue('permission', {});
        setValue('levels', 1);
        const permissionsData = generatePermissionsData();
        if (permissionsData && permissionsData.length > 0) {
          permissionsData.forEach(screen => {
            if (screen && screen.name) {
              setValue(screen.name, screen.value);
              setValue(screen.name, screen.value.type);
              setValue(screen.name + 'Actions', screen.value.actions);
            }
          });
        }
      };
      setInitialValues();
    }
  }, [props.selectedId, props.allUsersData]);

  useEffect(() => {
    setDisplay(false);
    setTimeout(() => setDisplay(true), 50);
  }, [formType])

  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
    watch
  } = useForm({
    resolver: yupResolver(schema),
  });

  const currentValues = formType === 'edit' ? watch() : null;

  //Funtion for enable & disable feature of update button if any changes are done
  function isDirtyConfirmation(previousDataObject) {
    let filteredFormFields = formFields.filter(field => field.isEditFormHidden === false);
    if (filteredFormFields.length > 0) {
      return filteredFormFields.every(field => {
        let isUpdated = DisableButton.getBooleanValue(field, getValues(field?.name), previousDataObject, props.type, currentValues);
        return isUpdated;
      });
    }
    return true;
  }

  useEffect(() => {
    if (formType === 'edit') {
      const isDirty = isDirtyConfirmation(selectedData);
      setIsUpdateDisable(isDirty);
      props.setDirty(!isDirty);
    }
  }, [currentValues]);

  const getSelectedData = (id,allData) => {
    if (id && id.startsWith('"') && id.endsWith('"')) {
      id = JSON.parse(id);
   // Parse id as a JSON string
    }
    setSelectedId(id);
    const data = allData.filter((row) => row._id === id);
    return (data.length > 0) ? data[0] : [];
  }
  

  let setValidations = (fields) => {
    let valObj = {};
    fields.forEach((field) => {
      if (field.show && ((!field.isAddFormHidden && formType === 'add') || (!field.isEditFormHidden && formType === 'edit')) && (field.required || ('validationRequired' in field && field.validationRequired == true))) {
        valObj[field.name] = getValidations(field);
      }
      if (field.show && field.type && (field.type == "email" || field.type == "relateAutoComplete" || field.name === 'phoneNumber')) {
        valObj[field.name] = getValidations(field);
      }
    });  
    
    return yup.object().shape(valObj)
  }

  let getValidations = (field) => {
    if ((props.type === 'Agents' || props.type === 'Clients') && (field.name === 'email' || field.name === 'phoneNumber')) {
      return yup.string().test('emailOrPhone', 'Either email or phone number is required', function () {
        const { email, phoneNumber } = getValues() || {};
        setFieldValidation(false);

        // Check if both fields are empty
        if (!email && !phoneNumber) {
          setFieldValidation(true);
          return this.createError({ path: 'email', message: ' ' });
        }
        if (email && !yup.string().email().isValidSync(email)) {
          return this.createError({ path: 'email', message: 'Invalid email format' });
        }
        if (phoneNumber && !yup.string().matches(/^\d{10}$/).isValidSync(phoneNumber)) {
          return this.createError({ path: 'phoneNumber', message: 'Phone number must be 10 digits' });
        }
        return true;
      });
    }
    if (field.type === 'number') {
      return yup.number()
        .typeError(`${field.label} field is a required valid number`)
        .required(`${field.label} is a required field`);
    } else if(field.type === 'email' && field.required){
        return yup.string().email().required(`${field.label} is a required field`).test({
          name: 'email-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(originalValue)) {
                throw this.createError({ path: field.name, message: 'Enter a valid email id' });
              }
            }
            return true;
          },
        });;
      }else if(field.type === 'password'){
       return yup.string().required().matches(getPasswordRegex(),"Password must contain at least 8 characters, one uppercase, one number and one special case character")
      }else if ((field.type === "relateAutoComplete" && field.isMultiple) || (field.isMultiSelect && field.type == 'dropDown')) {
        return field.required
          ? yup.array().min(1, ` please fill the above field`).required(`${field.label} is a required field`)
          : yup.array().test('is-valid-array', `${field.label} must be an array`, (value) => Array.isArray(value));
      }else if ((field.type === "relateAutoComplete" && field.required) || field.type === "permission") {
        return yup.object().typeError('Please select the value from dropdown').required(`${field.label} is a required field`);
      } 
      /**@CheckBox */
      else if(field.type === 'checkbox'){
        if(field.required){
        return yup.boolean().oneOf([true], 'Checkbox must be checked')
        } else {
          yup.boolean();
        }
      }

      //relateautocomplete validation for not required fields
      else if (field.type === "relateAutoComplete") {
        return yup.lazy(value => {
          if (value !== undefined && value !== null && value !== '') {
            return yup.object().test('is-object', 'Select a valid option', function(value) {
              if (typeof value !== 'object') {
                throw this.createError({ message: 'Select a valid option', path: field.name });
              }
              return true;
            });
          } else {
            return yup.mixed().nullable(); // No validation rule if field has no value
          }
        });
      } 

      //regex pattern matching validation for required fields
      else if (field.type === 'text' && field.regexPattern && field.required==true) {
        return yup.string().required(`${field.label} is a required field`).test({
          name: 'check-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const reg = new RegExp(field.regexPattern);
              if (!reg.test(originalValue)) {
                const customErrorMessage = disp(field);
                throw this.createError({ path: field.name, message: customErrorMessage });
              }
            }
            return true;
          },
        });
      }
      
      //regex pattern matching validation for not required fields
      else if (field.type === 'text' && field.regexPattern) {
        return yup.string().test({
          name: 'check-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const reg = new RegExp(field.regexPattern);
              if (!reg.test(originalValue)) {
                const customErrorMessage = disp(field);
                throw this.createError({ path: field.name, message: customErrorMessage });
              }
            }
            return true;
          },
        });
      }

      //email validation when not required field
      else if (field.type === 'email') {
        return yup.string().test({
          name: 'email-regex',
          test: function (value, { originalValue }) {
            if (originalValue) {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailRegex.test(originalValue)) {
                throw this.createError({ path: field.name, message: 'Enter a valid email id' });
              }
            }
            return true;
          },
        });
      }
      else if (field.type === 'WebSite') {
        const websiteRegex = /^(?:(?:(?:https?|ftp):)?\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
        return yup.string().required(`${field.label} is a required field`).matches(websiteRegex, `Enter a valid URL`);
      }
      else if (field.type === 'fileUpload') {
        return yup.array().required(`${field.label} is a required field`)
      }
      else if (field.type === 'date') {
        return yup.date().typeError(`${field.label} field is a required field`).required(`${field.label} is a required field`);
      }
      else {
        return yup.string().required(`${field.label} is a required field`);
      }
  }

  let disp = (f) => {
    let msg="";
    let m='';
    if(f.regexData.maxLengthRequired==true && f.regexData.showMaxField==true){
      m="Max length:" + f.regexData.maxLengthText + ", ";
      msg+=m;
    }
    if(f.regexData.minLengthRequired==true && f.regexData.showMaxField==true){
      m="Min length:" + f.regexData.minLengthText + ", ";
      msg+=m;
    }
    if(f.regexData.alphabetsRequired==true && f.regexData.showAlphaField==true){
      m="Letters" + ", ";
      msg+=m;
    }
    if(f.regexData.numbersRequired==true && f.regexData.showNumField==true){
      m="Numbers" + ", ";
      msg+=m;
    }
    if(f.regexData.showSCharField==true && f.regexData.specialCharRequired==true){
      m="Special characters:" + f.regexData.specialCharText + ", ";
      msg+=m;
    }
    msg=msg.slice(0,-2);
     return msg;
    
  };
  

  let onPageChange = async (event) => {
    let filters = { ...filterCriteria };
    if (event && event.rows) {
      let currentPage = event.page + 1;
      filters['limit'] = event.rows;
      filters['page'] = currentPage;
      setRows(event.rows);
      setPage(event.page);
      setFirst(event.first)
    }
    await getActivities(filters, selectedId);
  }

 let getTableFields = () => {
    let data = [
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 50,
        field: 'created',
        fieldType: 'Date',
        type: 'date',
        header: 'Date',
        filter: true,
        sortable: true,
        dateFormat: config.dateDayMonthFormat
      },
      {
        show: true,
        mobile: true,
        textAlign: 'center',
        width: 100,
        field: 'createdByName',
        header: 'Name',
        filter: true,
        sortable: true
      },
      {
        show: true,
        mobile: true,
        textAlign: 'left',
        width: 250,
        fieldType: 'notes',
        field: 'notes',
        header: 'Notes',
        filter: true,
        sortable: false
      },
    ];
    return data;
  };
  const getPaginator = () => {
      return (
        <PaginatorComponent
          totalRecords={totalRecordsLength}
          first={first}
          rows={rows}
          onPageChange={onPageChange}
          isWeb={true}
        />
      )
  }

  let getActivities = async (id) => {
    if (props.screenName === 'Clients' || props.screenName === 'Tickets') {
      let tFilterCriteria = filterCriteria;
      let urlHeading = window.location.href;
      let userID = decodeURIComponent(urlHeading.split('/').pop());
      let userIDWithoutQuotes = userID.replace(/^"(.*)"$/, '$1');
      userIDWithoutQuotes = userIDWithoutQuotes.replace(/"/g, '');
      tFilterCriteria['criteria'] = [{ key: 'contextId', value: id, type: 'eq' }];
      let url = `notes?filter=${JSON.stringify(tFilterCriteria)}`
      return fetchMethodRequest('GET', url).then(async (response) => {
        if (response) {
          let responseData = '',
            //  totalRecordsLength = totalRecordsLength;
            dateFormat;
          // let responseData = '', totalRecordsLength = totalRecordsLength;
          if (response && response['notes'] && response['notes'].length && response['notes'].length >= 0) {
            if (response.pagination && response.pagination.totalCount) {
              totalRecordsLength = response.pagination.totalCount;
            }
            if (props.screenName === 'Clients') {
              responseData = updateDateFormat(response['notes'], dateFormat);
            }else{
              responseData = response['notes'];
            }
          } else {
            if (response.pagination && (response.pagination.totalCount || response.pagination.totalCount == 0)) {
              totalRecordsLength = response.pagination.totalCount;
            }
          }

          setActivitiesData(responseData);
          setTotalRecordsLength(totalRecordsLength);
          setFilterCriteria(tFilterCriteria)

        }
      }).catch((err) => {
        return err
      })
    }
  }

  let getTableFieldItem = async (field) => {
    for (let i = 0; i < props.tablefieldsToShow.length; i++) {
      if (props.tablefieldsToShow[i].field == field) {
        return props.tablefieldsToShow[i];
      }
    }
    return null;
  }
  
  function getFormFields(type, formInputFields) {
    setFormType(type);
    if (props.formFields()) {
      let displayFormFields = formInputFields ? formInputFields : formFields;
      let sortType = '';
      if (type === 'add') {
        sortType = 'addFormOrder';
      } else if (type === 'edit') {
        sortType = 'editFormOrder';
      }
      if (sortType) {
        let sortFields = [];
        let otherFields = [];
        for (let i = 0; i < displayFormFields.length; i++) {
          if (displayFormFields[i][sortType]) {
            sortFields.push(displayFormFields[i]);
          }
          else {
            otherFields.push(displayFormFields[i]);
          }
        }
        sortFields = sortFields.sort((a, b) => a[sortType] - b[sortType]);
        displayFormFields = [...sortFields, ...otherFields];
        displayFormFields = displayFormFields.sort((a, b) => a[sortType] - b[sortType]);
      }
      setFormFields(displayFormFields);
      setValidations(displayFormFields);     
    }
  }

  let getFormFieldItem = async (key) => {
    let formFields = await props.formFields();
    for (let i = 0; i < formFields.length; i++) {
      if (formFields[i].name === key) {
        return formFields[i];
      }
    }
    return null;
  }
  //close delete modal
  let closeDeleteModal = async () => {
    setState({
      openDeleteModal: false,
      actions: ''
    })
  }
  let handleNextAndBackActions = async (sno) => {
    let { allUsersData } = props;
    let data = allUsersData.find((obj) => obj.Sno === sno)
    setUserData(data);
    setSelectedId(data['_id']);
    if (formType === 'view') {
      setEditRowDataID(data['_id']);
      await handleViewDisplay(data, 'view');
    } else {
      await getRowData(data, 'edit')
    }
    setFilterCriteria({ limit: 10, page: 1, criteria: [], sortfield: 'created', direction: 'desc' });
    setFirst(0);
    setRows(10);
    setTotalRecordsLength(0)
    await getActivities(data['_id']);
  }

  let getViewData = async (rowData, type, rowDataIndex, userData, _id) => {   
    setRowData(rowData);
    setEditRowDataID(_id);
    setFormType(type);
    setSslno(rowDataIndex);
    setUserData(userData);
  };
  let getIconValue = (rowData, labelKey) => {
    if (labelKey && labelKey.options && labelKey.options.length > 0) {
      for (let i = 0; i < labelKey.options.length; i++) {
        if (labelKey.options[i].value === rowData[labelKey.field]) {
          return labelKey.options[i].displayText;
        }
      }
    }
    return '';
  }

  async function handleViewDisplay(rowData, type) {
    let _id = rowData['_id'];
    let rowDataIndex = rowData['Sno']
    let keys = Object.keys(rowData);
    let newFields = [];
    if (newFields) {
      if (props.type) {
        if (rowData) {
          let values, fieldType, searchField, icon = false;

          keys.forEach(async function (key) {
            let labelKey = '';
            for (let i = 0; i < props.tablefieldsToShow.length; i++) {
              if (props.tablefieldsToShow[i].field == key) {
                labelKey = props.tablefieldsToShow[i];

                if (labelKey == null) {
                  labelKey = key;
                } else {
                  let val = rowData[key];
                  if (labelKey.fieldType === 'icon') {
                    val = getIconValue(rowData, labelKey);
                    icon = true;
                  }
                  fieldType = labelKey.fieldType ? labelKey.fieldType : null
                  searchField = labelKey.searchField ? labelKey.searchField : null
                  let options = labelKey.options ? labelKey.options : []
                  let isMul = labelKey.isMultiple ? labelKey.isMultiple : false
                  labelKey = labelKey.header
                  if (val) {
                    if (fieldType && searchField && isMul && fieldType == "relateAutoComplete") {
                      let displayNames = rowData[key].map((item) => {
                        return item[searchField];
                      });
                      let displayNamesString = displayNames.join(', ');

                      values = {
                        label: labelKey,
                        value: icon ? val : displayNamesString,
                        fieldType: fieldType
                      }
                    } else
                      if (fieldType && searchField && fieldType == "relateAutoComplete") {
                        values = {
                          label: labelKey,
                          value: icon ? val : rowData[key][searchField],
                          fieldType: fieldType
                        }
                      } else if (fieldType && fieldType == "fileUpload" && rowData[key] && rowData[key].length > 0) {
                        values = {
                          label: labelKey,
                          value: rowData[key],
                          fieldType: fieldType,
                          options: options,
                          tableItem: props.tablefieldsToShow[i]
                        }
                      } else if (labelKey == "Property Count") {
                        values = {
                          label: labelKey,
                          value: icon ? val : rowData[key].length,
                          fieldType: fieldType,
                        }
                      } else {
                        values = {
                          label: labelKey,
                          value: icon ? val : rowData[key],
                          fieldType: fieldType,
                          options: options,
                          tableItem: props.tablefieldsToShow[i]
                        }
                      }
                    newFields.push(values);
                    icon = false;
                  }
                }
              }
            }
          });
        }
      }
      setSelectedId(_id);
      setSelectedData(rowData);
      setFormType('view');
      setUserData(rowData);
      setDisplayBreadCrumbValue(rowData[displayBreadCrumbField]);
      setUserStatus(rowData['status'])
      await getViewData(newFields, 'view', rowDataIndex, rowData, _id);
      getActivities(_id);
    }
  }

  let getUserData = (_id) => {
    let data = props.allUsersData;
    for (let i = 0; i < data.length; i++) {
      if (data[i]['_id'] === _id) {
        return i
      }
    }
  }
  let updateDateFormat = (itemdata, dateFormat) => {
    let modifiedData = [];
    let tablefieldsToShow = getTableFields();
    for (let i = 0; i < itemdata.length; i++) {
      for (let k = 0; k < tablefieldsToShow.length; k++) {
        if ("Date" == tablefieldsToShow[k]['fieldType']) {
          itemdata[i][tablefieldsToShow[k]['field']] =
            dateFormats.formatDate(
              itemdata[i][tablefieldsToShow[k]['field']],
              tablefieldsToShow[k]['dateFormat']);
        }
      }
      modifiedData.push(itemdata[i])
    }
    return modifiedData;
  }
  //Get From Fields data on Edit
  async function getRowData (selectedRowInfo, type) {
    setSelectedData(selectedRowInfo);
    let keys = Object.keys(selectedRowInfo);
    selectedRowInfo.comments = "";
    selectedRowInfo.notes = "";
    let formFields = props.formFields();
    for (let i = 0; i < keys.length; i++) {
      let fieldItem = await getFormFieldItem(keys[i]);
      if (fieldItem) {
        // if ((fieldItem.type === 'multipleprofile' || fieldItem.type === 'ckeditor' || fieldItem.type === 'profile') && selectedRowInfo[fieldItem.name]) {
        //   setState({
        //     [fieldItem.name]: selectedRowInfo[fieldItem.name]
        //   })
        // }
      if (fieldItem.type === 'date') {
        let formattedDate = fieldItem.dateFormat ? fieldItem.dateFormat : config.dateDayMonthFormat;
        selectedRowInfo[fieldItem.name] = moment(selectedRowInfo[fieldItem.name], formattedDate).toDate();
      }
        if (fieldItem.type === 'time') {
          let formattedTime = moment(selectedRowInfo[fieldItem.name], config.fullDateTimeFormat).toDate();
          selectedRowInfo[fieldItem.name] = formattedTime;
        }
        if (fieldItem.type === 'dropDown' && fieldItem.isMultiSelect) {
          selectedRowInfo[fieldItem.name] = selectedRowInfo[fieldItem.name]
        } 
        else if ((fieldItem.type === 'dropDown' || fieldItem.type === 'radio') && fieldItem.dependent && fieldItem.dependent.length > 0) {
          let displayFormFields = formFields;
          setTimeout(() => {
            if (fieldItem.dependent && fieldItem.dependent.length > 0) {
              for (let i = 0; i < fieldItem.dependent.length; i++) {
                if (selectedRowInfo && selectedRowInfo[fieldItem.name] === Object.keys(fieldItem.dependent[i])[0]) {
                  if (fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]] && fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]].length > 0) {
                    displayFormFields = showField(displayFormFields, fieldItem.dependent[i][Object.keys(fieldItem.dependent[i])[0]], true);
                  }
                }
              }
              getFormFields(type, displayFormFields);
              // await setFormFields(displayFormFields);
            }
          }, 300);
        }
        if (fieldItem.name === 'locality' || fieldItem.name === 'city') {
          setSelectedArea(selectedRowInfo.selectedCity);
          if (selectedRowInfo.city) {
            selectedRowInfo.city = selectedRowInfo.selectedCity || {};
          }
          if (selectedRowInfo.locality && typeof selectedRowInfo.locality === 'string') {
            selectedRowInfo.locality = { area: selectedRowInfo.locality } || {};
          }
        }
      }
    }
    if (props.type && props.type == "Roles" && selectedRowInfo.roleType && selectedRowInfo.roleType == "Manager") {      
      setShoworHideLevelsField(true);
    }

    // if (selectedRowInfo['permissions']) {
    //   let permissionsArray = []
    //   let permissions = selectedRowInfo['permissions']
    //   if (permissions) {
    //     let keys = Object.keys(permissions);
    //     keys.forEach((element) => {
    //       if (element) {
    //         selectedRowInfo[element] = permissions[element];
    //         let permissonObj = {
    //           title: element,
    //         }
    //         if (type === 'edit') {
    //           if (selectedRowInfo[element] === 'Edit') {
    //             permissonObj.Edit = false;
    //           } else if (selectedRowInfo[element] === 'View') {
    //             permissonObj.View = false;
    //           } else if (selectedRowInfo[element] === 'NoView') {
    //             permissonObj.NoView = false;
    //           }
    //         } else {
    //           if (selectedRowInfo[element] === 'Edit' || selectedRowInfo[element] === 'View') {
    //             selectedRowInfo[element] = 'NoView';
    //             permissonObj.NoView = false;
    //           }
    //         }
    //         permissionsArray.push(permissonObj);
    //       }
    //     });
    //   }
     
    //   setMenuList(permissionsArray);
    // }
    if (props.load) {
      props.load(selectedRowInfo);
    }

    let fields = props.formFields();
    fields.forEach((item) => {
      setValue(item.name, '')
      if(!(item.isEditFormHidden === true && formType === 'edit')){
        setTimeout(()=>{
          setValue(item.name,selectedRowInfo[item.name])
        },300)      }
    })
    
    setIsLoading(false);
    setEditRowDataID(selectedRowInfo._id);
    setDisplayBreadCrumbValue(selectedRowInfo[displayBreadCrumbField]),
    setFormType(type);
    setUserData(selectedRowInfo);
    getActivities(selectedRowInfo._id)
    if (props.type !== 'Roles') {
      reset(selectedRowInfo);
    }
  }

  let closeFormModal = async () => {
    clearFormFields();
    props.getDataFromServer(props.filterCriteria);
    props.closeFormModal();
  }

  let flattenArray = (arrayVal) => {
    let val = '';
    if (arrayVal) {
      val = JSON.stringify(arrayVal);
      val = val.replace(/"/g, '')
        .replace(/\[/g, '')
        .replace(/]|\\/g, '')
        .replace(/{/g, '')
        .replace(/}/g, '')
        .replace(/,/g, ' , ')
        .replace(/:/g, ' : ');
    }
    return val;
  }
  let getActivtiesTableFieldItem = (field) => {
    let tablefieldsToShow = getTableFields()
    for (let i = 0; i < tablefieldsToShow.length; i++) {
      if (tablefieldsToShow[i].field == field) {
        return tablefieldsToShow[i];
      }
    }
    return null;
  }
  // hari need to move to derived class or controller
  let changeFieldValues = (item, column) => {
    let self = this, tableItem;
    tableItem = self.getActivtiesTableFieldItem(column.field);
    if (tableItem.fieldType === "Array") {
      let val = flattenArray(item[column.field]);
      return <span style={tableItem.style} title={val}>
        {val}
      </span>
    } else {
      if ((item[column.field]) && typeof item[column.field] !== 'object') {
        return item[column.field];
      }
    }
  }
  // changeFieldValues = async (item, column) => {
  //   let self = this, tableItem;
  //   tableItem = self.getActivtiesTableFieldItem(column.field);
  //   if (tableItem.fieldType === "Array") {
  //     let val = self.flattenArray(item[column.field]);
  //     return <span style={tableItem.style} title={val}>
  //       {val}
  //     </span>
  //   } else {
  //     // if (item[column.field] === 0) {
  //     //   return item[column.field];
  //     // }
  //     if ((item[column.field]) && typeof item[column.field] !== 'object') {
  //       return item[column.field];
  //     }
  //   }
  // }
  // form Submit
  let submit = (values) => {
    reset(values);
    if (values.roleType != "Manager") {
      values.levels = 1;
    }

    for (let formField of formFields) {
      if (formField.fieldType == "relateAutoComplete") {
        if (!formField.isMultiple && values[formField.name] && values[formField.name][formField.searchField]) {
          values[formField.name + "Search"] = values[formField.name][formField.searchField]
        } else if (formField.isMultiple && values[formField.name]) {
          let finalString = '';
          if (values[formField.name]?.length > 0) {
            values[formField.name].forEach(val => {
              if (formField.searchField) {
                finalString += val[formField.searchField] + ',';
              }
            })
          }
          values[formField.name + "Search"] = finalString;
        } else {
          values[formField.name] = null;
          values[formField.name + "Search"] = null;
        }
      }
    }
    if (props.type === 'MyProperties') {
      values.selectedCity = values.city;
      values.city = values.city?.city || '';
      values.locality = values.locality?.area || '';
    }
    if (values && Object.keys(values).length > 0) {
      saveDataToServer(values);
    } else {
      return;
    }
  }

  let clearFormFields = async () => {
    if (props.load) {
      props.load({});
    }
    let formData = [...formFields];
    formData.forEach((item) => {
      item.value = '';
      item.invalid = false;
      item.message = ''
    });    
    await setFormFields(formData)
  }

  let onEditorChange = async (evt) => {
    setState({
      [evt.editor.name]: evt.editor.getData()
    });
  }
  let ObjectbyString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  //send data to server
  let saveDataToServer = async (formValues) => {

    /**@CheckBox */
    Object.keys(formValues).forEach(key => {
      if (formValues[key] === undefined) {
        let formField = formFields.filter(d=>d.name == key);
          if(formField[0]?.type == 'checkbox'){
            formValues[key] = "false";
          }
      }
    });

    if (props.type === 'Agents' && formValues.email === '') {
      delete formValues.email;
    }
    let userBody = Object.assign({}, formValues);
    let validationExists;

    if (props.type === 'Agents') {
      delete userBody.lastLoginDate
    }
    
    setIsLoading(true);
    if (!validationExists) {
      if (formValues) {
        let method, apiUrl;
        if (formType === 'edit') {
          delete userBody.created;
          delete userBody.updated;
          delete userBody.createdBy;
          delete userBody.updatedBy;
          // delete userBody.email
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else if (formType === 'view') {
          delete userBody.email
          delete userBody.password;
          method = 'PUT';
          apiUrl = `${props.apiUrl}/${editRowDataID}`;
        } else {
          method = 'POST';
          apiUrl = props.apiUrl;
        }

        for (let i = 0; i < formFields.length > 0; i++) {
          if (formFields[i].show === false)
            continue;
          if (formFields[i].type === 'autoComplete') {
            if (formFields[i]["options"] && formFields[i]["options"].length > 0) {
              for (let j = 0; j < formFields[i]["options"].length; j++) {
                let keys = Object.keys(formFields[i]["options"][j])
                let values = Object.values(formFields[i]["options"][j]);
                if (keys && keys[0] && values && values[0] && formValues[formFields[i]["name"]][values[0]]) {
                  userBody[keys[0]] = formValues[formFields[i]["name"]][values[0]];
                }
              }
            }
          }
          if (formFields[i].type === 'multipleprofile' ||  formFields[i].type === 'profile') {
            // userBody[formFields[i].name] = formFields[i].name;
            userBody[formFields[i].name] = userBody[formFields[i].name];
          }
          if (formFields[i].type === 'permission') {
            let permissions = {};
            let keys = Object.keys(formValues);
            menuList.forEach((item, index) => {
              keys.forEach((key) => {
                if (item.title === key) {
                  permissions[item.title] = { 'type': formValues[key], 'actions': formValues[key + 'Actions'] }
                }
              })
            })
            userBody.permissions = { ...permissions }
          }
        }


        return fetchMethodRequest(method, apiUrl, userBody)
          .then(async (response) => {
            let sessionexpired = await localStorage.getItem('sessionexpired')
            if (sessionexpired === "true") {              
              setSessionExpiryModal(true);
            }
            if (response && response.respCode) {
              if (props?.setValueOnSuccess) {
                props.setValueOnSuccess(response)
              }
              await props.getDataFromServer(props.filterCriteria);
              showToasterMessage(response.respMessage, 'success');
              if (props.displayViewOfForm === 'modal') {
                props.closeFormModal('save', response.quantityId);
              } else {
                if (formType !== 'add') {                  
                  props.closeFormModal('save', response.quantityId);
                } else {
                  props.closeFormModal('save', response.quantityId);
                }

              }
              clearFormFields();
              props.reset();
            } else if (response && response.errorMessage) {
              showToasterMessage(response.errorMessage, 'error');
            }
           
            setIsLoading(false);
          }).catch((err) => {
            return err;
          });
      } else {
        return;
      }
    }
  }

  //getStores
  let getRecords = (valve, key, apiUrl, name) => {
    let filterCriteria = {}
    filterCriteria['criteria'] = [{ key: key, value: valve, type: 'eq' }];
    let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`
    fetchMethodRequest('GET', url).then(async (response) => {
      if (response) {
        let states = response[apiUrl];
        let roleOptions = [];
        if (states && states.length > 0) {
          for (let i = 0; i < states.length; i++) {
            roleOptions.push({ label: states[i][name], value: states[i][name] })
          }
         
          await setRoleOptions(roleOptions);
        } else {          
          await setRoleOptions([]);
        }
      }
    }).catch((err) => {
      return err
    })
  }

  // //Handle auto complete data
  let handleAutoCompleteData = async (value, name) => {

  }

  let getDropdownMultiselect = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoMultiSelectField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              label={item.label}
              options={item.options}
              optionLabel={item.optionLabel}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    )
  }

  let handleFnEnableControlsBasedOnValue2 = async (e, dependent, fieldName, clearValue) => {
    let updatedFromFields = formFields;
    if (dependent && dependent.length > 0) {
      for (let i = 0; i < dependent.length; i++) {
        if (e && e === Object.keys(dependent[i])[0]) {
        }
        else {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            updatedFromFields = await showField(updatedFromFields, dependent[i][Object.keys(dependent[i])[0]], false, fieldName, clearValue);
          }
        }
      }
      for (let i = 0; i < dependent.length; i++) {
        if (e && e === Object.keys(dependent[i])[0]) {
          if (dependent[i][Object.keys(dependent[i])[0]] && dependent[i][Object.keys(dependent[i])[0]].length > 0) {
            updatedFromFields = await showField(updatedFromFields, dependent[i][Object.keys(dependent[i])[0]], true, fieldName, clearValue);
          }
        }
      }
      getFormFields(formType, updatedFromFields);
      // await setFormFields(updatedFromFields);
      const schema = setValidations(updatedFromFields);
      setSchema(schema);
    }
  }

   let handleFnEnableControlsBasedOnValue = async (e, dependent) => {
    if (e && e == "Manager") {   
      setShoworHideLevelsField(true);
    } else {      
      setShoworHideLevelsField(false);
    }
  }

  let getDropdown = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
              item={item}
              screenName={props.screenName}
              handleFnEnableControlsBasedOnValue={handleFnEnableControlsBasedOnValue}
              handleFnEnableControlsBasedOnValue2={handleFnEnableControlsBasedOnValue2}
            />)}
        />
      </div>
    )
  }

  let showField = (formFields, itemNames, show = true, fieldName, clearValue = false) => {
    let value = true;
    if (show === false) {
      value = show;
    }
    for (let i = 0; i < formFields.length; i++) {
      for (let itemName of itemNames) {
        if (formFields[i].name === itemName) {
          formFields[i].show = value;
          if (value === false) {
            setValue(formFields[i].name, clearValue ? '' : getValues(formFields[i].name) ? getValues(formFields[i].name) : formFields[i].value ? formFields[i].value : '');
          } else {
            setValue(formFields[i].name, getValues(formFields[i].name) ? getValues(formFields[i].name) : formFields[i].value ? formFields[i].value : '');
          }
        }
        if (formFields[i]?.combinedDependent && formFields[i]?.combinedDependent?.options?.length > 0) {
          let combinedDependent = formFields[i]?.combinedDependent?.options
          let showCondition = combinedDependent.every((depField) => {
            return getValues(depField.key) === depField.value
          })
          formFields[i].show = showCondition;
          let hiddenField = formFields.find(field => field.name === formFields[i]?.combinedDependent?.hiddenFields)
          if (showCondition) {
            if (hiddenField) {
              hiddenField.show = false;
              setValue(hiddenField.name, '');
            }
          } else {
            if (hiddenField && getValues('propertyFor')) {
              hiddenField.show = getValues('propertyFor') === 'Sale';
              if (!getValues('propertyFor') === 'Sale') {
                setValue(hiddenField.name, '');
              }
            }
            setValue(formFields[i].name, '');
          }
        }
      }
    }
    return formFields;
  }
  
  const generatePermissionsData = () => {
    const options = [
      { label: 'NoView', value: 'NoView' },
      { label: 'View', value: 'View' },
      { label: 'Edit', value: 'Edit' }
    ];
    let permissions = {};
    if (formType === 'edit' && selectedId) {
      const rowData = props.allUsersData.find((obj) => obj._id === selectedId);
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }
    else if (formType == "edit" && props.tableRowData) {
      let rowData = props.tableRowData;
      if (rowData && rowData.permissions && typeof rowData.permissions === 'object') {
        permissions = { ...rowData.permissions };
      }
    }
    return menuList.map((screen) => {
      return screen.name ? {
        'name': screen.name,
        'label': screen.name,
        'value': permissions[screen.name] ? permissions[screen.name] : { type: 'View' },
        'options': options
      } : null;
    }).filter(item => item !== null);
  };

  const getScreenPermissions = () => {
    const permissionsData = generatePermissionsData();

    return (
      <div className='row '>
        {permissionsData && permissionsData.length > 0 && permissionsData.map((item, index) => (
          <div key={index} className={`col-md-${props.displayViewOfForm === 'sidebar' ? '12' : '6'} mb-3`}>
            {getRadio(index, item)}
          </div>
        ))}
      </div>
    );
  };

  let getRolePermissions = () =>{
    return (
      <div className="form form--horizontal">
        <div className="form__form-group row">
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
          <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
            <span className='pr-4 pl-5'>{'Edit'}</span>
            <span className='pr-3 pl-2'>{'View'}</span>
            <span className='pr-2 '>{'No View'}</span>
          </div>
        </div>        
        {menuList && menuList.length > 0 ?
          menuList.map((item, index) => {
            return <div key={index} className="form__form-group col-sm-6">
              <span className="form__form-group-label ">{item.title}</span>
              <div className="form__form-group-field ">
              </div>
            </div>
          }) : null
        }
      </div>
    );
  }

  let getFileUpload = (i, item) => {
    const bytesPerMB = 1024 * 1024;
    let fileSize = item.maxFileSize * bytesPerMB;
    let otherFormats = ".pdf, .doc, .docx, .xls, .xlsx, .csv, .ppt, .json, .txt"

    return (
      <div style={{ marginTop: '25px' }}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoFileUpload
              url={`uploads/uploadAttachments?type=${item.imagePath.toLowerCase()}`}
              imagePath={item.imagePath.toLowerCase()}
              formType={formType}
              showPreview={true}
              acceptType={item.fileType === 'img/video' ? "image/*, video/*" : item.fileType === 'other' ? otherFormats : '*'}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              type={props.type}
              isMultiple={item.isMultipleRequired}
              maxFileSize={fileSize}
              label={item.label}
            />)}
        />
      </div>
    )
  }


  let getFileName = async (file, item) => {
    let image = file;
    await setState({
      [item.name]: image
    });
  }

  let getDate = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="date"
              formType={formType}
              
            />)}
        />
      </div>
    )
  }

  let getTime = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoDateField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder="time"
              timeOnly={true}
            />)}
        />
      </div>
    )
  }

  let getCkEditor = (i, item) => {
    let self = this;
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoCKEditorField
              field={field}
              errors={errors}
              label={item.label}
            />
          )}
        />
      </div>
  
      // <span>Testing</span>
    )
  }

  let getRadio = (i, item) => {
    return (
      <div key={i}>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoRadioButtons
              markReq={item.required}
              options={item.options}
              input={field}
              id={field.id}
              name={field.name}
              field={field}              
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              item={item}
              control={control}
              getValues={getValues}
              setValue={setValue}
              handleFnEnableControlsBasedOnValue2={handleFnEnableControlsBasedOnValue2}
              type={props.type}
            />)}
        />
      </div>
    )
  }

  let getAutoComplete = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}              
              screen={props.type}
              searchApi={item.searchApi}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}              
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              getValues={getValues}
              watch={watch}
              setValue={setValue}
              selectedArea={selectedArea}
              criteria={item.criteria}
            />)}
        />
      </div>
    )
  }

  let getTextArea = (i, item) => {
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoTextareaField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              defVal={item.value}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
              label={item.label}
            />)}
        />
      </div>
    )
  }

  let getButtonGroup = (i, item) => {
    return (
      <ButtonToolbar className='mt-0'>
        <ButtonGroup size="sm" >
          {item.options.map((button) => {
            return <Button style={{ paddingTop: 3, paddingBottom: 3 }}
              color="primary"
              outline size="sm"
              active={state[`is${button.label}ReOrder`]}
              onClick={() => handleReOrdering(button.value)}>{button.label}</Button>
          })
          }
        </ButtonGroup>
      </ButtonToolbar>
    )
  }

  let getDefault = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              // type={item.type ? item.type : "text"}
              type={item.type && item.type=="number" ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              placeholder={item.placeholder ? item.placeholder : null}
              displayPrice={item.displayPrice}
              item={item}
              rowData={selectedData}
            />)}
        />
      </div>
    )
  }
  /**@CheckBox Component */
  let getCheckbox = (i, item) => {
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => {
            field.value = field.value ? field.value :false;
            return <DoCheckboxField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defaultChecked={false}
              placeholder={item.placeholder ? item.placeholder : null}
              options={item.options}
              multiple={item.multiple}
              multipleSelect={item.multipleSelect}
              customWidth={item.optionsWidth}
            />
          }}
        />
      </div>
    )
  }

  let getPassword = (i, item) => {
    console.log(item);
    let { t } = props;
    return (
      <div>
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoPasswordField
              markReq={item.required}
              input={field}
              id={item.id ? item.id : null}
              name={field.name}
              field={field}
              fieldState={fieldState}
              errors={errors}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  let showPassword = (e) => {
    e.preventDefault();
  }


  let getButtonToolbar = () => {
    let { t } = props;
    return (
      <div className='d-flex'>
        <div className='col-12 px-0'>
          <div className="d-flex justify-content-center mb-1">
            {fieldValidation &&
              <span>
                <small className="text-danger">Either Email or Phone number is required</small>
              </span>
            }
          </div>
          <span className='float-end'>
            <ButtonToolbar>
              {props.displayViewOfForm === 'modal' && formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                {t('Back') ? t('Back') : 'Back'}

              </Button> :

                props.displayViewOfForm === 'sideForm' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                  {t('Close') ? t('Close') : 'Close'}
                </Button>

                  : formType !== 'add' ? <Button color='primary' type="button"
                    // onClick={() => setFormType("view")}
                    onClick={() => closeFormModal()}
                  >
                    {t('Back') ? t('Back') : 'Back'}
                  </Button> : formType === 'add' ? <Button color='primary' type="button" onClick={() => closeFormModal()}>
                    {t('Back') ? t('Back') : 'Back'}
                  </Button> : null}
              {formType && formType === 'add' ?
                <Button color='primary' outline type="submit">
                  {t('Save') ? t('Save') : 'Save'}
                </Button> :
                <Button color='primary' outline type="submit" disabled={isUpdateDisable}>
                  {t('Update') ? t('Update') : 'Update'}
                </Button>
              }
            </ButtonToolbar>
          </span>
        </div>
      </div>
    )
  }

  let getData = (data, type) => {
    // setIsEditFields(true);
    setFormType(type)
    getRowData(data, type);
  }

  let getEditButton = () => {
    let { t } = props;
    return (
      <>
        <div className='mx-1 d-flex justify-content-end my-2'>
          {props.editRequired && 
           ( displayViewOfForm === 'screen' ?
                <Button color='primary' outline type="submit" onClick={() => getData(userData, 'edit')}>
                  {t('Edit') ? t('Edit') : 'Edit'}
                </Button>
              :
              <Button color='primary' outline type="submit" onClick={() => getData(userData, 'edit')}>
                {t('Edit') ? t('Edit') : 'Edit'}
              </Button>)
          }
          <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={closeFormModal}>&nbsp;&nbsp;
            <Button color='primary' outline type="submit"> Close </Button>
          </Link>
        </div>
      </>
    )
  }

  let getModalHeader = () => {
    let { t } = props;
    return (
      <ModalHeader className="modal__header">
        <p className="bold-text  modal__title">
          {formType &&
            formType === 'edit' ?
            t('Edit') ? t('Edit') : 'Edit' :
            formType &&
              formType === 'view' ?
              t('View') ? t('View') : 'View' : t('Add') ? t('Add') : 'Add'
          }{' '}
          {props.type ? props.type : null}
        </p>
      </ModalHeader>
    )
  }

  let getDependentFields = (type, formType) => {
    return null;
  }

  let getMultiplePhotoUpload = (i, item) => {
    return (
      <div>
        <Field key={i}
          onRef={(ref) => (profileUploadRef = ref)}
          name={item.name ? item.name : null}
          component={RenderFileInputField}
          label={item.label}
          type='profile'
          id={'photo'}
          acceptType={'image/*'}
          url={apiCalls.LocationImagePath}
          getMulipleFileName={(file) => getMulipleFileName(file, item)}
          multiple={true}
        />
        <Scrollbar>
          <div className='mindivForMultipleupload' >
            {state[item.name] && state[item.name].length > 0 ? state[item.name].map((imagLocationItem, imagLocationIndex) => (<div className='col-md-2' style={{ padding: '20px' }}>
              <div key={imagLocationIndex} style={{ justifyContent: 'center' }}>
                <img
                  key={imagLocationIndex}
                  src={`${config.imgUrl}${item.imagePath}/${imagLocationItem['file']}`}
                  className='detailsImgStyle' />
                <FontAwesomeIcon icon='times-circle' className='timesCircleIcon'
                  onClick={() => removeMultipleUploadedImages(imagLocationIndex, item)}
                />
              </div>
            </div>)) : null
            }
          </div>
        </Scrollbar>
      </div>
    )
  }
  let getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectActions={selectActions}
        deleteSelectedRow={handleActions}
        confirmModalText={confirmModalText}
      />
    )
  }
  // Store selected Images in state
  let getMulipleFileName = async (file, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.push({ "file": file })
    await setState({
      [item.name]: multipleLocationImage
    });
  }

  // Remove selected image from state
  let removeMultipleUploadedImages = async (imagLocationIndex, item) => {
    let multipleLocationImage = state[item.name];
    multipleLocationImage.splice(imagLocationIndex, 1);
    await setState({
      [item.name]: multipleLocationImage
    })
  }

  let getItemField = (item, i) => {
    let { t } = props;
    return (
      <div id={item.name} key={props.type + i}
        className={(item.isAddFormHidden && formType === 'add') ? 'd-none' :
         (selectedData.propertyStatus === 'Need Approval' && item.name === 'propertyStatus') ? 'd-none' :
          item.name == "levels" && props.type == "Roles" && !showorHideLevelsField ? 'd-none' :
            (item.isEditFormHidden && formType === 'edit') ? 'd-none' : (item.type === 'ckeditor' || item.type === 'permission' || item.type === 'multipleprofile' ? 'col-sm-12' : (
              (item.type === 'autoComplete' && item.name === 'assignedTo') ||
                item.name === 'closebutton' ? 'col-sm-4' : displayViewOfForm == "sideForm" ? "col-sm-12" : item?.customWidth ? item?.customWidth : "col-sm-6"))}
      >
        <div className="form__form-group mb-3 ml-1" >
          {/* <label className="form__form-group-label">
            {t(item.label)}
          </label> */}
          {item.type === 'dropDown' && item.isMultiSelect ?
            getDropdownMultiselect(i, item)
            : item.type === 'dropDown' ?
              getDropdown(i, item)
              : item.name === 'closebutton' ?
                getCloseButton(i, item)
                : item.type === 'fileUpload' ?
                  getFileUpload(i, item)
                  : item.type === 'date' ?
                    getDate(i, item)
                    : item.type === 'time' ?
                      getTime(i, item)
                      : item.type === 'ckeditor' ?
                        getCkEditor(i, item)
                        : item.type === 'empty' ?
                          <div> </div>
                          : item.type === 'radio' ?
                            getRadio(i, item)
                            : item.type === 'permission' ?
                              // getRolePermissions()
                              getScreenPermissions()
                              : item.type === 'autoComplete' ?
                                getAutoComplete(i, item)
                                : item.type === 'relateAutoComplete' ?
                                  getAutoComplete(i, item)
                                  : item.type === 'textarea' ?
                                    getTextArea(i, item)
                                    : item.type === 'buttonGroup' ?
                                      item.options && item.options.length > 0 ? getButtonGroup(i, item) : null
                                      : item.type === 'multipleprofile' ?
                                        getMultiplePhotoUpload(i, item) :
                                        item.type == "password" ?
                                          getPassword(i, item)
                                          : item.type == "encryptedField" ?
                                            getPassword(i, item)
                                            /**@CheckBox */
                                            : item.type == 'checkbox' ?
                                              getCheckbox(i, item)
                                              : item.type == 'customFormField' && item.customFormField ? item.customFormField(i, item, setValueOnSuccessResponse)
                                                  : getDefault(i, item)

          }
        </div>
      </div>

    )
  }

  let getFields = () => {
    let allFields = <div></div>
    let item;
    for (let i = 0; i < formFields.length; i++) {
      item = formFields[i];
      if (item.show === false || (item.isAddFormHidden === true && formType === 'add') || (item.isEditFormHidden === true && formType === 'edit')) {

      } else {
        allFields = <>{allFields}{getItemField(item, i)}</>
      }
    }
    return allFields;
  }
  let setSlno = async (actionType) => {
    let { totalRecords, first, rows, onPageChange, isWeb } = props;
    let sLno = slno;
    if (actionType === 'back') {
      if (sLno !== 0) {
        setSslno((slno) => slno -1);
        handleNextAndBackActions(sLno - 1)
      } else {
        setSslno(slno + 1);
      }
    } else if (actionType === 'next') {
      let total = '';
      total = props.allUsersData.length;
      if (sLno !== total) {
        setSslno((slno) => slno + 1);        
        setColored(true)
        handleNextAndBackActions(sLno + 1);
      } else {
        setSslno(slno);
      }
    }
  }
  
  let getPaginationWithIcons = () => {
    let { first, rows, page, allUsersData } = props;
    let totalLength = (first && first !== 0 && allUsersData.length >= 20) ? (allUsersData.length + first) : allUsersData.length <= 20 ? first + allUsersData.length : allUsersData.length;

    let isSelectedData = false;
    for (let i = 0; i < allUsersData.length; i++) {
      if (allUsersData[i].Sno === selectedData['Sno']) {
        isSelectedData = true
      }
    }

    return (
      <div className='d-flex h-100'>
        <div className='pagenumber-indicator'>
          {slno === 0 ? 1 : slno} / {totalLength}&nbsp;&nbsp;
        </div>
        <div>
          <ButtonGroup className='mb-0'>
            <Button color="primary"
              outline
              disabled={!isSelectedData || (slno === (page - 1) * rows + 1) ? true : false}
              size="sm"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('back')}
            >
              <FontAwesomeIcon
                icon='chevron-left'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
            <Button color="primary"
              outline
              disabled={!isSelectedData || (slno === totalLength) ? true : false}
              size="sm"
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              className="p-1 ml-auto mt-1 mb-0"
              onClick={() => setSlno('next')}
            >
              <FontAwesomeIcon
                icon='chevron-right'
                className='pl-1' size='lg'
                data-toggle="tool-tip" title="List"

              />
            </Button>
          </ButtonGroup>
        </div>

      </div>
    )
  }

  //getModalBody
  let getModalBody = () => {
    return (
      <ModalBody className="modal__body mb-0 ">
        <Card className='pb-0 cardForListMargin'>
          <CardBody className='tableCardBody'>
            <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>
              <Loader loader={isLoading} />
              <div className="row form" >
                {getFields()}
                {sessionExpiryModal ?
                  <SessionExpiryModal
                    SOpen={sessionExpiryModal}
                  />
                  : null
                }
                {props.getDependentFields && props.getDependentFields(props.type, formType)}
              </div>
              {getButtonToolbar()}
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    )
  }
  
  const onErrors = (data) => {
  }

  //getScreenBody
  let getScreenBody = () => {
    return (
      <form  onSubmit={handleSubmit(submit,onErrors)} autoComplete={'off'}>
        <Loader loader={isLoading} />
        <div className={props.displayViewOfForm=='sideForm' ? "col-12" : formType === 'add' ? "row form pt-3" : "row form "}>
          {getFields()}         
          {sessionExpiryModal ?
            <SessionExpiryModal
              SOpen={sessionExpiryModal}
            />
            : null
          }
          {props.getDependentFields && props.getDependentFields(props.type, formType)}
        </div>
        {getButtonToolbar()}
        <br/>
        {formType == 'edit' ? showNotesAndComments() : ""}
      </form>
    )
  }

  //getModalView
  let getModalView = () => {
    let { handleSubmit } = props;
    let modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
    return (
      <Dialog
        visible={props.openFormModal}
        onHide={closeFormModal}
        draggable={false}
        style={{ width: '50vw' }}
        header={getModalHeader()}
        closeOnEscape={false}
        focusOnShow={false}
        blockScroll={true}
      >
        {formType === 'view' ?
          <ModalBody className="modal__body mb-0 pt-2">
            <Card className='pb-0 cardForListMargin'>
              <CardBody className='tableCardBody'>
                {getViewBody()}
                {getEditButton()}
              </CardBody>
            </Card>
          </ModalBody> : getModalBody(handleSubmit)}
      </Dialog>
    )
  }
  let cancelUserPwdResetModal = async () => {    
    setOpenUserPasswordResetModal(false);
    setActions('')
  }
  let getUserPasswordResetModal = () => {
    return (
      <UserPasswordResetModal
        openUserPasswordResetModal={openUserPasswordResetModal}
        userId={editRowDataID}
        cancelReset={cancelUserPwdResetModal}
        entityType={props.entityType}
      />
    )
  }
  let getHeader = () => {
    let { t } = props;
    if (props.type !== 'Searches') {
      return (
        <div className='d-flex'>
          <div className='col-12 px-0 pb-1'>
            <span className='float-left pt-2'>
              <h4 style={{ textTransform: 'capitalize' }}><b>
                <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/${props.routeTo}`} onClick={closeFormModal}>
                  {formType &&
                    formType === 'edit' ?
                    t('Edit') ? t('Edit') : 'Edit' :
                    formType &&
                      formType === 'view' ?
                      t('View') ? t('View') : 'View' : t('Add') ? t('Add') : 'Add'
                  }{' '}
                  {props.screenHeader ? props.screenHeader : props.displayName?.slice(0, -1)}
                </Link>
                {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
              </b> </h4>
            </span>
          </div>
        </div>
      )
    }
  }
  let submitActionsData = async (method, url) => {
    return fetchMethodRequest(method, url)
      .then(async (response) => {
        let sessionexpired = localStorage.getItem('sessionexpired')
        if (sessionexpired == "true") {          
          setSessionExpiryModal(true);
        }        
        setOpenDeleteModal(false);
        setActions('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          // await props.getDataFromServer(props.filterCriteria);
          await props.closeFormModal();
          await handleNextAndBackActions();
          // props.closeFormModal('save');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  let handleActions = async () => {
    let apiUrl = props.apiUrl, url = '', method = '';
    if (confirmType === 'Delete') {
      method = 'DELETE';
      url = `${apiUrl}/${editRowDataID}`;
      submitActionsData(method, url)
    }
    if (confirmType === 'Block') {
      method = 'PUT';
      url = `${apiUrl}/block/${editRowDataID}?block=true`;
      submitActionsData(method, url)
    }
    if (confirmType === 'ResetPassword') {      
      setOpenUserPasswordResetModal(true);
      setOpenDeleteModal(false);
    }

  }

  // conformation for delete item
  let deleteConfirmAction = async (rowData, selectActions) => {    
    setOpenDeleteModal(true);
    setSelectActions(selectActions);
  }
  let confirmActionType = async (type) => {
    if (type === 'Delete') {    
     await setConfirmType(type);
     await setConfirmModalText('Are you sure want to Delete')
      deleteConfirmAction()
    } else if (type === 'Block') {      
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Block')

      deleteConfirmAction()
    } else if (type === 'ResetPassword') {
      await setState({
        confirmType: type,
        confirmModalText: 'Are you sure want to Reset Password',
        openUserPasswordResetModal: true,
        openDeleteModal: false
      })
      await setConfirmType(type);
      await setConfirmModalText('Are you sure want to Reset Password');
      await setOpenUserPasswordResetModal(true);
      await setOpenDeleteModal(false);
      
    }
  }

  //onActionsChange
  let onActionsChange = async (event, type) => {
    if (type == 'dropdownFilter') {
      await setState({
        [event.target.name]: event.target.value,
      })
      confirmActionType(event.target.value)
    }
  }
  let getViewBody = () => {
    let { t } = props;
    return (<div>

      <div className='row'>
        <div className='col-sm-4 pb-2'>
          {/* {props.actionsTypes && props.actionsTypes.length > 0 ? <Dropdown
            style={{ minWidth: '10%', lineHeight: 1.3, }}
            className='mr-3'
            // appendTo={document.body}
            name='actions'
            value={actions}
            options={props.actionsTypes}
            placeholder={t('Actions')}
            onChange={(e) => onActionsChange(e, 'dropdownFilter')}
          /> : null} */}
        </div>
        <div className='col-sm-8 text-lg-right'>
          {originalTableFields && originalTableFields.length > 0 ? originalTableFields.map((item, index) => {
            return item.fieldType === 'Badge' && item.options && item.options.length > 0 ? item.options.map((optionsItem, optionsIndex) => {
              return (
                <Button key={optionsIndex} color='primary' style={{ marginRight: '5px' }} onClick={() => saveDataToServer({ "status": optionsItem.value })} disabled={userStatus === optionsItem.value ? true : false}>{optionsItem.value}</Button>              )
            }) : null
          }) : null}
        </div>
      </div>
      {rowData && rowData.length > 0 ?
        <div className="row form" >
          {rowData.map((item, i) => {
            return (
              item.value !== "" && item.value !== null && item.label !== 'S.No' && ((item.value && typeof item.value === 'number') || (item.value?.length && item.value.length > 0)) && !(item.label === 'Comments' || item.label === "Notes") ?
                <div className={displayViewOfForm == "sideForm" ? 'col-12' : 'col-sm-6 '} key={i}>
                  <div className="row">
                    <div
                      className="col-5 paddingRowDataCol"
                    >
                      <span className='fontWeight' style={{ fontWeight: "bold" }}>
                        {item.label}
                      </span>
                    </div>

                    <div className="col-7 paddingOfRowData"
                    >
                      <span>
                        {(typeof (item.value) === 'string') && item.fieldType && (item.fieldType == 'dropDown' || item.fieldType == 'radio') ? // for the dropdowns
                          getBadgeData(item, item.value)
                          : !(typeof (item.value) === 'string') && item.fieldType && item.fieldType == 'dropDown' ? // for the multiselect dropdowns
                            getMultiSelectBadge(item, item.value) : item.fieldType && item.fieldType == 'fileUpload' ? getFielUploadView(item?.tableItem, { [item?.tableItem?.field]: item?.value }, item?.tableItem)
                              : item.fieldType && item.fieldType == 'customType' && item?.tableItem ? getCustomTypeView(item, item?.tableItem, item?.tableItem)
                                : item?.tableItem?.fieldUse == "dateAndTime" ? dateAndTimeFormat(item.value, item.tableItem)
                                  : item.value
                        }
                      </span>
                    </div>
                  </div>
                </div> : null
            );
          })}
        </div>
        : null
      }
    </div>

    )
  }
  let getBadgeData = (element, value) => {
    let mcolor = props.getColorFromOptions(element.options, value);
    return (<Badge color={mcolor} pill >{value}</Badge>)
  }

  const getMultiSelectBadge = (element, value) => {
    let mcolor = props.getColorFromOptions(element.options, value);
    return (
      <div>
        {value.map((badge, index) => (
          <Badge key={index} color={mcolor} pill style={{ margin: '1px' }}>
            {badge}
          </Badge>
        ))}
      </div>
    )
  }

  //sorting fields
  let sortChange = (event) => {
    setSelectedRows('')
    let newSortCount = sortCount;
    if (event && event['sortField']) {
      newSortCount = newSortCount == 0 ? newSortCount + 1 : 0;
      let sortField = event['sortField'];
      let filters = filterCriteria;
      filters['direction'] = newSortCount == 0 ? "desc" : 'asc';
      filters['sortfield'] = sortField;
      setSortCount(newSortCount);
      setFilterCriteria(filters);
      getActivities(filters, selectedId);
    }
  }

  let getColumns = (e, d) => {
    let { t } = props;    
    let tablefieldsToShow = getTableFields();
    if (tablefieldsToShow && tablefieldsToShow.length > 0) {
      return tablefieldsToShow.map((item, i) => {
        let column = (item.show &&
          <Column key={item.field + i}
            style={{
              maxwidth: item.width,
              padding: 2,
            }}
            bodyStyle={{
              // textOverflow: 'ellipsis',
              // overflow: 'hidden',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              textAlign: item.field == 'status' || item.field == 'role' ? 'center' : item.textAlign
            }}
            field={item.field}
            header={t(item.header)}
            body={self.changeFieldValues}
            headerStyle={{
              padding: 4, fontWeight: 500, width: item.width, fontSize: 13,
              color: config.whiteColor, backgroundColor: config.templateColor
            }}
            filter={false}
            sortable={item.sortable ? true : false}
            filterPlaceholder={item.placeholder ? item.placeholder : 'search'}

            selectionMode={item.selectionMode}
          />
        )
        return column;
      })
    }
  }
  let getDataTable = () => {
    let dt;

    return (      
      <DataTable
        ref={(el) => dt = el}
        value={activitiesData}
        totalRecords={totalRecordsLength}
        paginator={true}
        lazy={false}
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 30, 50]}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} / {totalRecords}"
        paginatorPosition={"bottom"}
        resizableColumns={true}
        columnResizeMode="expand"
        // onSort={sortChange} for the api call and sorting, commenting for now
        globalFilter={props.globalFilter}
        onFilter={props.onFilterChange}
        scrollable={true}
        selection={false}
        scrollHeight='1000px'
        // style={{ marginTop: 0 }}
        emptyMessage={configMessages.noRecords}
        sortMode="single"
        // sortField="fname"
        // sortOrder={-1}
        // selectionMode={'multiple'}
        metaKeySelection={false}
        loading={isLoading}
        style={activitiesData && activitiesData.length == 0 ?
          { textAlign: 'center' }
          : null}
      >
        {getColumns()}
      </DataTable>
    )
  }
  let getActivitiesHeader = () => {
    let { t } = props
    return (
      <div className='col-12  pb-1'>
        <span className='float-left pt-2'>
          <h4 style={{ textTransform: 'capitalize' }}><b>

          <Link to={props.routeTo.startsWith("/") ? props.routeTo : `/activities`} onClick={closeFormModal}>
              {t('Activities')}
            </Link>
            {formType !== 'add' && displayBreadCrumbValue ? ` / ${displayBreadCrumbValue} ` : null}
          </b> </h4>
        </span>
        <span className='float-right pt-2'>
          {/* {getPaginator()} */}
        </span>
      </div>
    )
  }
  //getScreenView
  let getScreenView = () => {
    let { handleSubmit } = props;
    return (
      <div>       
        {props.openFormModal ? <div>

          <div className='d-flex justify-content-between pb-2 mx-1'>
            <div >
              {getHeader()}
            </div>
            <div >
              {(formType !== 'add' && formType !== 'notes') && getPaginationWithIcons()}
            </div>
          </div>
          
          {(formType !== 'view' && formType !== 'notes') ? getScreenBody(handleSubmit) : formType === 'notes' ? getOpenAddNotesModal() : getViewBody()}
          {formType === 'view' ? getEditButton() : null}

          {/* No Need of the Activity Table in the View  */}
          {formType !== 'add' && formType !== 'edit' && (props.screenName === 'Clients' || props.screenName === 'Tickets') ?

            <div>
              {JSON.parse(localStorage.getItem('rolePermissions')).Activities != config.noView ?  // Activities table won't show if there is no activities screen
                <div>
                  <div className='row'>
                    {/* {getActivitiesHeader()} */}
                    {(formType === 'view') ? getNotesHeader() : null}
                  </div>
                  <div className='row'>
                    <div className='col-sm-12'>
                      {props.screenName === 'Clients' ? getDataTable() : getChat()}
                    </div>
                  </div>
                </div> : null
              }
            </div> : null
            
          }
        </div> : null
        }
      </div>
    );
  }

  let getNotesHeader = () => {
    return (
      <div className='col-12  pb-1'>
        <span className='float-left pt-2'>
          <h4 style={{ textTransform: 'capitalize' }}>
            <b className='themeColorText'>
              {props.screenName === 'Clients' ? "Notes" : "Comments"}
            </b>
          </h4>
        </span>
      </div>
    )
  }
  const getChat = () => {
    return (
      <Chat
        activitiesData={activitiesData}
        rowData={props.tableRowData}
        setActivitiesData={setActivitiesData}
      />
    )
  }

  function setValueOnSuccessResponse(settingObj, response) {
    if (settingObj?.fieldType === 'relateAutoComplete') {
      if (settingObj.apiUrl && settingObj.method && settingObj.field) {
        let filter = { page: 1, criteria: [], sortfield: 'created', direction: 'desc' }
        filter.criteria.push({ key: settingObj?.filterKey, value: response?.[settingObj.responseKey], type: "eq" });
        let apiUrl = `${settingObj.apiUrl}?filter=${JSON.stringify(filter)}`
        fetchMethodRequest(settingObj.method, apiUrl).then(async (response) => {
          if (response && response[settingObj.apiUrl]?.length > 0) {
            setValue(settingObj.field, response[settingObj.apiUrl][0])
          }
        }).catch((err) => {
          return err
        })
      }

    }
  }

  let showNotesAndComments = () => {
    if ((props.screenName === 'Clients' || props.screenName === 'Tickets')) {
      const rolePermissions = JSON.parse(localStorage.getItem('rolePermissions'));
      if (rolePermissions.Activities !== config.noView) {
        return (
          <div>
            <div className="row">
              {getNotesHeader()}
            </div>
            <div className="row">
              <div className="col-sm-12">
                {props.screenName === 'Clients' ? getDataTable() : getChat()}
              </div>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  async function addNotes (rowData, type) {
    setFormType(type);
    setRowData(rowData);
    getOpenAddNotesModal();
    getActivities(rowData._id);
  }

  const getOpenAddNotesModal = () => {
    const contextType = props.type == 'Clients' ? 'CLIENTS' : "TICKETS";
    return (
      <AddNotesModal
        type={props.type}
        rowData={props.tableRowData}
        closeAddNotesModal={closeFormModal}
        contextTypeAction={contextType}
        getActivities={getActivities}
      />
    )
  }

  const getFielUploadView = (tableItem, item, column) => {
    if (tableItem.fieldType == 'fileUpload') {
      if (tableItem.isMultipleRequired && tableItem.fileType === 'img/video') {
        return (
          <div>
            {item && item[column.field] && item[column.field].length > 0 ? (
              <div>
                <FileGalleryView
                  item={item}
                  column={column}
                  type={props.customType ? props.customType : props.type}
                />
              </div>
            ) : 'null'}
          </div>
        );
      } else {
        if (tableItem.fileType === 'img/video') {
          return <div className='textElipses'>
            {item && item[column.field] && item[column.field].length > 0 ?
              <Link to={`${config.imgUrl}${props.type.toLowerCase()}/${item[column.field][0]}`} target="_blank" style={tableItem.style}>{item[column.field][0]}</Link> : null
            }
          </div>
        } else {
          return (
            <div className='textElipses'>
              {item[column.field]?.length > 0 && item[column.field].map((img, index) => {
                return (
                  <a key={index} href={`${config.imgUrl}${props.type.toLowerCase()}/${img}`} target='_blank'>
                    <img title={img} src={configImages?.[img?.split('.')?.[img?.split('.')?.length - 1]]} alt="" />
                  </a>
                );
              })
              }
            </div>
          );
        }
      }
    }
  }

  const getCustomTypeView = (item, column, tableItem) => {
    if (tableItem.customFieldType == 'amount') {
      return <div>
        {typeof item?.value === 'number' ? formatIndianCurrency(item?.value) : item?.value}
      </div >
    }
    else if (tableItem.customFieldType == 'attachField') {
      if (viewData[tableItem?.attachField] && item?.value) {
        return <span>
          {item?.value + ' ' + viewData[tableItem?.attachField]}
        </span>
      }
      return <span>{item?.value}</span>
    }
    else if (tableItem.customFieldType === 'parentType') {
      if (tableItem.parentType) {
        return <span>{tableItem.parentType(item)}</span>
      }
    }
    else if (tableItem.type === 'ckeditor' && item.value) {
      let updatedValue = updateCkEditorValues(item.value);
      return <span>{updatedValue}</span>
    }
  }

  
    let { displayViewOfForm } = props;
    return (
      display && <div>
        {displayViewOfForm === 'modal' ?
          getModalView() : displayViewOfForm === 'screen' || displayViewOfForm=='sideForm'?
            getScreenView() : null
        }
        {openDeleteModal ? getDeleteRowModal() : null}
        {openUserPasswordResetModal ? getUserPasswordResetModal() : null}
      </div>
    );
  
}

export default withTranslation('common')(FormModal);