import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const AgentSessions = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Agent Sessions");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
   
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "officeObjId",
        fieldName: "officeObjId",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Name",
        derivedValue: "officeObjId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "center",
        show: true,
        field: "officeObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "agentObjId",
        fieldName: "agentObjId",
        type: "relateAutoComplete",
        placeholder: "Agent Name",
        value: "",
        label: "Agent Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Agent Name",
        derivedValue: "agentObjId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "agentObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1014,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Agents",
        searchApi: "agents",
        textAlign: "center",
        show: true,
        field: "agentObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromTime",
        fieldName: "fromTime",
        type: "date",
        placeholder: "From Time",
        value: "",
        label: "From Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "From Time",
        derivedValue: "fromTime=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        id: "fromTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldUse: 'dateAndTime'
      },
      {
        name: "toTime",
        fieldName: "toTime",
        type: "date",
        placeholder: "To Time",
        value: "",
        label: "To Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "To Time",
        derivedValue: "toTime=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        id: "toTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM/DD/YYYY HH:mm",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldUse: 'dateAndTime'
      },
      {
        name: "sessionTime",
        fieldName: "sessionTime",
        type: "text",
        placeholder: "Session Time",
        value: "",
        label: "Session Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Session Time",
        derivedValue: "sessionTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "sessionTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "sessionTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      }
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "fromTime",
        fieldName: "fromTime",
        type: "date",
        placeholder: "From Time",
        value: "",
        label: "From Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "fromTime=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "fromTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "toTime",
        fieldName: "toTime",
        type: "date",
        placeholder: "To Time",
        value: "",
        label: "To Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "toTime=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        id: "toTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM/DD/YYYY HH:mm",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "sessionTime",
        fieldName: "sessionTime",
        type: "text",
        placeholder: "Session Time",
        value: "",
        label: "Session Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "sessionTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "sessionTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "officeObjId",
        fieldName: "officeObjId",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "officeObjId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "agentObjId",
        fieldName: "agentObjId",
        type: "relateAutoComplete",
        placeholder: "Agent Name",
        value: "",
        label: "Agent Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "agentObjId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "agentObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1014,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Agents",
        searchApi: "agents",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fromTime",
        fieldName: "fromTime",
        type: "date",
        placeholder: "From Time",
        value: "",
        label: "From Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "From Time",
        derivedValue: "fromTime=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "fromTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "fromTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "toTime",
        fieldName: "toTime",
        type: "date",
        placeholder: "To Time",
        value: "",
        label: "To Time",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "To Time",
        derivedValue: "toTime=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: false,
        id: "toTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "MM/DD/YYYY HH:mm",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "toTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "sessionTime",
        fieldName: "sessionTime",
        type: "text",
        placeholder: "Session Time",
        value: "",
        label: "Session Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Session Time",
        derivedValue: "sessionTime=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "sessionTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "sessionTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "officeObjId",
        fieldName: "officeObjId",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Name",
        derivedValue: "officeObjId=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "center",
        show: true,
        field: "officeObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "agentObjId",
        fieldName: "agentObjId",
        type: "relateAutoComplete",
        placeholder: "Agent Name",
        value: "",
        label: "Agent Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Agent Name",
        derivedValue: "agentObjId=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "agentObjId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1014,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Agents",
        searchApi: "agents",
        textAlign: "center",
        show: true,
        field: "agentObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.agentSessions}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={false}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={false}
          printRequried={false}
          // actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.agentSessions}
          globalSearch={"Search"}
          displayName="Agent Sessions"
          type="AgentSessions"
          routeTo={apiCalls.agentSessions}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.agentSessions}
          apiUrl={apiCalls.agentSessions}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="agentSessions"
          apiUrl={apiCalls.agentSessions}
        />
      ) : null}
    </span>
  );
};

export default AgentSessions;
