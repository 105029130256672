import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../App/Loader';
import fetchMethodRequest from '../../config/service';
import CountDisplay from './components/CountDisplay';
import { Chart } from 'primereact/chart';

const Dashboard = () => {
  const { t } = useTranslation('common');

  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [graphData, setGraphdata] = useState({});

  useEffect(() => {
    getCountData();
  }, [])

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const data = {
      labels: graphData?.xAxislables,
      datasets: [
        {
          label: graphData?.agentData?.name,
          data: graphData?.agentData?.agentCounts,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4
        },
        {
          label: graphData?.clientData?.name,
          data: graphData?.clientData?.clientCounts,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4
        },
        {
          label: graphData?.officeData?.name,
          data: graphData?.officeData?.officeCounts,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--yellow-500'),
          tension: 0.4
        },
        {
          label: graphData?.propertyData?.name,
          data: graphData?.propertyData?.propertyCounts,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--red-500'),
          tension: 0.4
        },
        {
          label: graphData?.savedSearchData?.name,
          data: graphData?.savedSearchData?.savedSearchCounts,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--green-500'),
          tension: 0.4
        }
      ]
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder
          }
        }
      }
    };

    setChartData(data);
    setChartOptions(options);

  }, [graphData]);

  const getCountData = () => {
    setIsLoading(true);
    fetchMethodRequest('GET', `dashboard/adminDashboard`)
      .then(async (response) => {
        if (response && response.respCode) {
          setIsLoading(false);
          setDashboardData(response.currentActiveReport);
          setGraphdata(response);
        }
      }).catch((err) => {
        return err;
      });
  }

  return (
    <Container className="dashboard ml-5" style={{ width: '98%' }}>
      <Loader loader={isLoading} />

      <Row>
        <Col md={12}>
          <h3 className="page-title mb-1 fw-bold">{t('DashBoard')}</h3>
        </Col>
      </Row>

      {/* Counts */}
      <CountDisplay dashboardData={dashboardData} />

      <div>
        <Row>
          <Col lg={6} xl={6} >
            <div className="card">
              <Chart type="line" data={chartData} options={chartOptions} />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
