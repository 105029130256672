import React, { useState } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountDisplay = (props) => {

	const [itemsList] = useState([
		{ displayName: 'Agents', route: '/agents', color: '#d2acac', key: 'agents', icon: 'users', count: 'totalAgents', filter: { key: "status", value: ["Active"], type: "in" } },
		{ displayName: 'Clients', route: '/clients', color: '#95a0af', key: 'clients', icon: 'users', count: 'totalClients', filter: { key: "status", value: ["Active"], type: "in" } },
		{ displayName: 'Offices', route: '/offices', color: '#abb188', key: 'offices', icon: 'building', count: 'totalOffices' },
		{ displayName: 'My Properties', route: '/myProperties', color: '#39cccc', key: 'myProperties', icon: 'warehouse', count: 'totalProperties' },
		{ displayName: 'My Saved Searches', route: '/savedSearches', color: '#72c872', key: 'savedSearches', icon: 'heart', count: 'totalsavedSearchs' },
	]);


	return (
		<Container className='px-0'>
			<Row>
				{itemsList.map((item, index) => (
					<Col key={index} style={{ width: '20%' }}>
						<Link to={item.route} state={{ criteria: JSON.stringify(item.filter) || {} }}>
							<Card className='dashboard_cards'>
								<CardBody className="dashboard__card-widget d-flex justify-content-between" style={{ backgroundColor: item.color }}>
									<div>
										<div className="card__title">
											<h5 className="bold-text text-white mx-1" >{item.displayName}</h5>
										</div>
										<div className="dashboard__total text-white">
											<span>
												{props?.dashboardData?.[item.count] || '0'}
											</span>
										</div>
									</div>
									<div className="card-icon tile-icons">
										<FontAwesomeIcon icon={["fa", item.icon]} className="menu-icon" />
									</div>
								</CardBody>
							</Card>
						</Link>
					</Col>
				))}
			</Row>
		</Container >
	);
}

export default CountDisplay;